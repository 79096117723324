<template>
  <div class="main">
    <div class="el-tabs-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="今天" name="first">
          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%"
          >
            <el-table-column prop="outName" label="出土方" />
            <el-table-column prop="inName" label="进土方" />
            <el-table-column prop="times" label="完成运输车次" />
            <el-table-column prop="tempInCount" label="临时车辆入场车次" />
            <el-table-column prop="openInCount" label="入口手动开闸次数" />
            <el-table-column prop="tempOutCount" label="临时车辆出场车次" />
            <el-table-column prop="openOutCount" label="出口手动开闸次数" />
            <el-table-column prop="outCount" label="道闸出场车次" />
            <el-table-column prop="openCount" label="手动开闸次数" />
          </el-table>
          <div class="block">
            <el-pagination
              background
              :current-page.sync="formData.page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="昨天" name="second">
          <el-table
            :data="tableData"
            border
            :header-cell-style="$rowClass"
            style="width: 100%"
          >
            <el-table-column prop="outName" label="出土方" />
            <el-table-column prop="inName" label="进土方" />
            <el-table-column prop="times" label="完成运输车次" />
            <el-table-column prop="tempInCount" label="临时车辆入场车次" />
            <el-table-column prop="openInCount" label="入口手动开闸次数" />
            <el-table-column prop="tempOutCount" label="临时车辆出场车次" />
            <el-table-column prop="openOutCount" label="出口手动开闸次数" />
            <el-table-column prop="outCount" label="道闸出场车次" />
            <el-table-column prop="openCount" label="手动开闸次数" />
          </el-table>
          <div class="block">
            <el-pagination
              background
              :current-page.sync="formData.page"
              @current-change="handlerCurPage"
              :total="count"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { $gp_getHomeData } from '../../api/index';
import { createDays } from '../../utils/index';

export default {
  name: 'home',
  data() {
    return {
      count: 0,
      formData: {
        page: 1,
        size: 10,
      },
      tableData: [],
      activeName: 'first',
      tableDataCount: [],
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName = tab.name;
      this.formData.page = 1;
      this.getTableData();
    },
    /**
     * @description: 创建今年、上月、本月的开始结束日期
     */
    createStartDateAndEndDate() {
      this.timeData = this.timeData.map((item) => {
        const date = new Date();
        const y = date.getFullYear(); // 年
        const pm = this.fulZero(date.getMonth()); // 上月
        const cm = this.fulZero(date.getMonth() + 1); // 本月
        const d = this.fulZero(date.getDate()); // 天
        const today = `${y}-${cm}-${d}`;
        const days = createDays(pm, y);
        let val;
        if (item.id === 1) {
          val = {
            startDate: `${y}-01-01`,
            endDate: today,
          };
        } else if (item.id === 2) {
          val = {
            startDate: `${y}-${pm}-01`,
            endDate: `${y}-${pm}-${days.length}`,
          };
        } else {
          val = {
            startDate: `${y}-${cm}-01`,
            endDate: today,
          };
        }
        return { ...item, val };
      });
    },
    /**
     * @description: 获取今日second 昨日first yyyy-MM-dd 日期格式
     * @return {String}
     */
    getDateByType() {
      let date = new Date();
      if (this.activeName === 'second') {
        const datMs = 24 * 3600 * 1000;
        const yestToday = date.getTime() - datMs;
        date = new Date(yestToday);
      }
      const y = date.getFullYear(); // 年
      const cm = this.fulZero(date.getMonth() + 1); // 本月
      const d = this.fulZero(date.getDate()); // 天
      const dateStr = `${y}-${cm}-${d}`;
      return dateStr;
    },
    /**
     * @description: 补0，不满10前面补0
     * @param {Number} val
     * @return {String}
     */
    fulZero(val) {
      return val < 10 ? `0${val}` : val;
    },
    handlerCurPage(val) {
      this.formData.page = val;
      this.getTableData();
    },
    getTableData() {
      this.formData.date = this.getDateByType();
      $gp_getHomeData(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    getTotalData() {
      return ['-', '-'];
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 10px 0 30px;
}
.block {
  padding: 10px 0;
}
.w300 {
  width: 300px;
}
.el-tabs-box {
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
</style>
