<!--
 * @Author: 许波
 * @Date: 2021-07-14 20:44:39
 * @LastEditTime: 2023-02-09 23:26:01
-->
<template>
  <div class="module-wrap" v-if="userInfo.curUserInfo">
    <div class="flex user-main">
      <div class="flex-item line">
        <el-form>
          <el-form-item label="姓名：">
            {{curUserInfo.name}}
          </el-form-item>
          <el-form-item label="手机号码：">
            {{curUserInfo.mobile}}
          </el-form-item>
          <el-form-item label="登录账号：">
            {{curUserInfo.username}}
          </el-form-item>
        </el-form>
        <!-- <el-form v-else>
          <el-form-item :label="formatName()">
            {{curUserInfo.name}}
          </el-form-item>
          <el-form-item label="编号：" v-if="isQtcpUser">
            {{curUserInfo.uuid}}
          </el-form-item>
          <el-form-item label="登录账号：">
            {{curUserInfo.username}}
          </el-form-item>
          <el-form-item label="联系人：">
            {{curUserInfo.person}}
          </el-form-item>
          <el-form-item label="联系电话：">
            {{curUserInfo.contact}}
          </el-form-item>
          <el-form-item label="设备登录名：">
            {{curUserInfo.deviceUsername}}
          </el-form-item>
          <el-form-item label="设备密码：">
            {{curUserInfo.devicePassword}}
          </el-form-item>
          <el-form-item label="状态：">
            {{curUserInfo.status === 'BUILDING' ? '建设中' : '已完工'}}
          </el-form-item>
          <el-form-item :label="formatAddress()">
            <span class="theme-color pointer" @click="dialogVisible=true">
              {{curUserInfo.address}}
            </span>
          </el-form-item>
        </el-form> -->
      </div>
      <div class="flex-item">
        <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        >
          <el-form-item label="原密码" prop="oldPass" required>
            <el-input type="password" v-model="formData.oldPass" placeholder="请输入原密码" />
          </el-form-item>
          <el-form-item label="新密码"  prop="newPass" required>
            <el-input type="password" v-model="formData.newPass" placeholder="请输新密码" />
          </el-form-item>
          <el-form-item label="重复新密码" prop="newPass2" required>
            <el-input type="password" v-model="formData.newPass2" placeholder="请输再次输入新密码" />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="resetPwd">修改密码</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="flex fcc back-btn"><el-button @click="$router.back()">返回</el-button></div>
    <el-dialog
      title="查看地点"
      :visible.sync="dialogVisible"
      width="80%">
      <div class="address">{{curUserInfo.address}}</div>
      <div class="qtc-map">
        <Gmap ref="gmap" :position="position" :address="curUserInfo.address"></Gmap>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { $gp_editPwd } from '../../api/index';
import { globalClose } from '../../utils/utils';
import Gmap from '../../components/Gmap.vue';

export default {
  name: 'ResetPwd',
  components: { Gmap },
  data() {
    return {
      dialogVisible: false,
      formData: {},
      rules: {
        oldPass: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPass: [{ required: true, message: '请输新密码', trigger: 'blur' }],
        newPass2: [{ required: true, message: '请输再次输入新密码', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isGroupUser() {
      return this.userInfo.role === 'GROUP_MANAGER';
    },
    isProUser() {
      return this.userInfo.role === 'PROJECT_MANAGER';
    },
    isQtcpUser() {
      return this.userInfo.role === 'QTC_MANAGER';
    },
    curUserInfo() {
      return this.userInfo.curUserInfo;
    },
    position() {
      return [this.userInfo.curUserInfo.lng, this.userInfo.curUserInfo.lat];
    },
  },

  watch: {
    updateTime(val) {
      console.log(val.routeName);
      console.log(this.$options.name);
      const [routeName] = val.routeName.split('-');
      if (routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    console.log('this.userInfo', this.userInfo);
  },
  methods: {
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.formData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    resetPwd() {
      const checkResult = this.validate();
      if (!checkResult) return;
      if (this.formData.newPass !== this.formData.newPass2) {
        this.$message.error('两次新密码不一致~');
        return;
      }
      delete this.formData.newPass2;
      $gp_editPwd(this.formData).then(() => {
        this.$message.success('密码修改成功！');
      });
    },
    refreshData() {
      console.log('刷新...');
    },
    formatName() {
      return this.isQtcpUser ? '弃土场名称：' : '项目名称：';
    },
    formatAddress() {
      return this.isQtcpUser ? '弃土场地址：' : '项目地址：';
    },
  },
};
</script>

<style lang="scss" scoped>
  .user-item{
    width: 40%;
  }
  .user-main{
    min-height: 350px;
    border-bottom: 1px solid #f2f2f2;
  }
  .back-btn{
    padding-top: 20px;
  }
  .line{
    border-right: 1px solid #f2f2f2;
  }
  .address{
    margin-top: -30px;
    margin-bottom: 20px;
  }
</style>
